import React from 'react'
import { get } from 'lodash-es'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import BasicPageTemplate from '../../templates/basic-page'
import { usePreview } from '../utils/use-prismic-preview'

const BasicPagePrismic = ({ data }) => {
  const { prismicBasicPage } = usePreview(data)
  const title = get(prismicBasicPage, 'data.title')
  const content = get(prismicBasicPage, 'data.content')

  return <BasicPageTemplate title={title} content={content} />
}

BasicPagePrismic.defaultProps = {
  data: {},
}

BasicPagePrismic.propTypes = {
  data: PropTypes.object,
}

export default BasicPagePrismic

export const pageQuery = graphql`
  query BasicPageByUID($uid: String!, $lang: String!) {
    prismicBasicPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      type
      lang

      alternate_languages {
        id
        lang
        type
        uid
      }
      data {
        title
        meta_title
        meta_description
        canonical_url
        no_index
        card_description
        card_image {
          localFile {
            childImageSharp {
              fixed(width: 1200, height: 630) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        card_image_square {
          localFile {
            childImageSharp {
              fixed(width: 512, height: 512) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        card_title
        content {
          html
          raw
        }
      }
    }
  }
`
